import styled from '@emotion/styled';
import { theme } from '../../styles';
import Image from 'gatsby-image';

export const ArticleMainContainer = styled.div`
  position: relative;
  margin-top: 45px;

  @media (min-width: ${theme.breakpoints.md}) {
    margin-top: 65px;
  }

  @media (min-width: ${theme.breakpoints.l}) {
    margin-top: 71px;
  }

  .ArticleTemplate_Header {
    width: 100%;
    border-bottom: 1px solid black;
    padding: 15px 0;
  }

  .ArticleTemplate_Title {
    letter-spacing: 0.03em;
    padding: 30px 0;
    line-height: 1.1;

    @media (max-width: ${theme.breakpoints.md}) {
      line-height: normal;
    }

    @media (min-width: ${theme.breakpoints.md}) {
      padding: 40px 0 10px;
    }
  }

  .ArticleTemplate_Body {
    font-size: ${theme.fonts.m};

    @media (min-width: ${theme.breakpoints.md}) {
      margin-top: 40px;
    }

    h1 {
      font-family: ${theme.fontFamily.primary};
      font-size: ${theme.scale(theme.fonts.xxxl_small, 0.2)};
      margin-bottom: 20px;
    }

    h2 {
      font-family: ${theme.fontFamily.primary};
      font-size: ${theme.scale(theme.fonts.xxl_large, 0.2)};
      margin-bottom: 20px;
    }

    h3 {
      font-family: ${theme.fontFamily.primary};
      font-size: ${theme.scale(theme.fonts.xxl_medium, 0.2)};
      margin-bottom: 20px;
    }

    h4 {
      font-family: ${theme.fontFamily.primary};
      font-size: ${theme.scale(theme.fonts.xxl_small, 0.2)};
      margin-bottom: 20px;
      line-height: 1.4;
    }

    h5 {
      font-family: ${theme.fontFamily.primary};
      font-size: ${theme.scale(theme.fonts.xl_medium, 0.2)};
      margin-bottom: 20px;
      line-height: 1.4;
    }

    h6 {
      font-family: ${theme.fontFamily.primary};
      font-size: ${theme.scale(theme.fonts.l, 0.2)};
      margin-bottom: 20px;
      line-height: 1.4;
    }

    li {
      padding-left: 25px;
      margin-bottom: 15px;
      position: relative;
      font-weight: 400;

      &::before {
        content: '';
        position: absolute;
        top: 2px;
        left: -20px;
        width: 15px;
        height: 15px;
        border-radius: 100%;
        background: ${theme.colors.primaryred};
      }
    }

    ul {
      list-style: none;
    }

    a {
      text-decoration: underline;
    }
  }

  .BigCircle__Parralax {
    position: absolute;
    z-index: -1;
    max-width: 300px;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% - 40px);
    max-width: 340px;
    top: -750px;

    @media (min-width: ${theme.breakpoints.s}) {
      max-width: 400px;
    }

    @media (min-width: ${theme.breakpoints.md}) {
      width: 70vw;
      max-width: ${theme.scale('850px')};
    }
  }
`;

export const ArticleHeroContainer = styled.div`
  display: flex;
  width: 100%;

  .ArticleTemplateHero__Img {
    width: 100%;
  }
`;

export const ArticleTemplateRelated = styled.div`
  width: 100%;
  margin-top: 80px;

  @media (min-width: ${theme.breakpoints.md}) {
    margin-top: 150px;
  }

  .ArticleTemplate_Title-secondary {
    text-transform: uppercase;
    padding-bottom: 25px;
    margin-bottom: 60px;
    border-bottom: 1px solid black;
  }
`;

export const ArticleTemplateBodyImage = styled(Image)`
  max-width: 500px;
  width: 100%;
  margin-top: 30px;
`;
